import React from "react";

function Section(props) {
  let backgroundClass = "section ";
  let shadowClasses = "section-shadow ";
  let classes = "section-container ";

  if (props.seeThrough) {
    backgroundClass += " section-see-through";
  }

  if (props.className) {
    classes += " " + props.className;
  }

  if (props.shadowClass) {
    shadowClasses += " " + props.shadowClass;
  }

  if (props.row) {
    classes += " flex-row";
  } else {
    classes += " flex-col flex-align ";
  }

  const bgStyles = { width: "100%", backgroundImage: "none" };
  if (props.bgImg) {
    bgStyles.backgroundImage = `url(${props.bgImg})`;
    // ? set the image to cover the whole section
    bgStyles.backgroundSize = "cover";
    bgStyles.backgroundRepeat = "no-repeat";
    bgStyles.backgroundPosition = "center";
  }

  const shadowStyles = { backgroundColor: "rgb(0, 0, 0, 0)" };
  if (props.bgShadow) {
    shadowStyles.backgroundColor = `rgb(0, 0, 0, ${props.bgShadow / 100})`;
  }

  if (props.py) {
    shadowClasses += " py-" + props.py;
  }

  if (props.containerWidth) {
    classes += " section-container-" + props.containerWidth;
  }

  if (props.respSplit) {
    classes += " section-container-resp-split";
  }

  return (
    <section className={backgroundClass} style={bgStyles}>
      <div className={shadowClasses} style={shadowStyles}>
        <div className={classes + " " + props.className}>{props.children}</div>
      </div>
    </section>
  );
}

export default Section;
