import React from "react";

const InstaTag = ({ username, imageUrl, className }) => {
  return (
    <a
      className="px-3  mb-3 insta-tag flex-row flex-align text-black"
      style={{ justifyContent: "flex-start" }}
      href="https://www.instagram.com/valhalla_strength_brisbane/"
      target="_blank"
    >
      <img
        className={"insta-pic " + className}
        src={imageUrl}
        alt="Instagram Profile Picture"
      />
      <p className="mb-0 ">{username}</p>
    </a>
  );
};

export default InstaTag;

// ? default props
InstaTag.defaultProps = {
  className: "",
  username: "@valhalla_strength_brisbane",
  imageUrl: "/media/img/logos/insta-logo.jpg",
};
