import React from "react";

function Link(props) {
  let classes = " ";
  let xml = (
    <div onClick={props.onClick} className={"link link-" + props.color}>
      {props.children}
    </div>
  );

  if (props.larger) {
    classes += " link-larger ";
  }

  if (props.link) {
    xml = (
      <a href={props.link} className={"link link-" + props.color + classes}>
        {props.children}
      </a>
    );
    if (props.newTab) {
      xml = (
        <a
          target="_blank"
          href={props.link}
          className={"link link-" + props.color + classes}
        >
          {props.children}
        </a>
      );
    }
  }

  return xml;
}

export default Link;

// ? defaults
Link.defaultProps = {
  color: "",
  onClick: () => {
    console.log("clicked ");
  },
};
