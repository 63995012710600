import React from "react";
import NavBurger from "./NavBurger";
import { useState } from "react";
import NavList from "./NavList";
import { Link } from "react-router-dom";

function Navbar(props) {
  const [Opened, SetOpened] = useState(0);

  // ? hamburger button click
  const clickedButton = () => {
    SetOpened(!Opened);
  };

  const closeNav = () => {
    SetOpened(false);
  };

  let openClass;
  let logoClasses = "nav-logo flex-row flex-align ";
  let navOpenClass;
  // ? set the scroll on the body to overflow hidden
  if (Opened) {
    document.body.style.overflow = "hidden";
    openClass = "nav-burger-opened";
    logoClasses += "nav-logo-opened";
    navOpenClass = "nav-bar-opened";
  } else {
    document.body.style.overflow = "auto";
    openClass = "";
    logoClasses += "";
  }

  const clearConsole = () => {
    console.clear();
  };

  const links = props.links;

  return (
    <header className={"App-header py-2 " + navOpenClass}>
      {/* <div className="nav-link-list-cover"></div> */}
      <nav className={"flex-row flex-align m-0 "}>
        <Link to="/">
          <div onClick={closeNav} className={logoClasses}>
            <img
              src="/media/img/logos/logo-white.png"
              className="nav-logo-img"
              alt="logo"
              style={{ height: "64px" }}
            />
          </div>
        </Link>
        <NavBurger
          notificationCount={0}
          onClick={clickedButton}
          openClass={openClass}
        />
        <NavList list={links} isOpen={Opened} SetOpened={SetOpened} />
      </nav>
    </header>
  );
}

export default Navbar;
