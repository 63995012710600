import React from "react";
import Paragraph from "../general/Paragraph";
import GoogleMaps from "../specifics/GoogleMaps";

function MapSection(props) {
  // var map;
  // function initMap() {
  //   map = new google.maps.Map(document.getElementById("map"), {
  //     center: { lat: -34.397, lng: 150.644 },
  //     zoom: 8,
  //   });
  // }

  return (
    <div className="w-100 flex-col flex-align max-w-1 min-w-1">
      <Paragraph header className="" center larger>
        Your Journey Starts Here!
      </Paragraph>
      {/* <GoogleMaps  /> */}

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11916.697399116054!2d153.06649723939051!3d-27.380233064062676!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b9158848d987747%3A0xa7066c0af7882516!2sValhalla%20Strength!5e0!3m2!1sen!2sus!4v1699943502893!5m2!1sen!2sus"
        style={{
          border: 0,
          height: "450px",
          width: "100%",
          background: "#efefef",
          borderRadius: "8px",
        }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="google maps"
      ></iframe>
      <Paragraph className="m-0" center smaller bold>
        4/90 Northlink Place, Virginia QLD 4014
      </Paragraph>
    </div>
  );
}

export default MapSection;
