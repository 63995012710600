export const navLinks = [
  { name: "Home", link: "/" },
  { name: "Memberships", link: "/memberships" },
  {
    name: "Services",
    listId: 1,
    subList: [
      { name: "Coaching", link: "/coaching" },
      { name: "Online Coaching", link: "/online-coaching" },
      { name: "Nutrition", link: "/nutrition" },
      { name: "Rehabilitation", link: "/rehabilitation" },
    ],
  },
  { name: "Competitions", link: "/competitions" },
  { name: "About", link: "/about" },
  { name: "Contact Us", link: "/contact-us" },
];
