import React from "react";

function Hero(props) {
  let coverXML = "";

  if (props.cover) {
    coverXML = (
      <div
        className="hero-cover"
        style={{ backgroundColor: `rgba(0,0,0,${props.coverShadow / 100} )` }}
      >
        {props.cover}
      </div>
    );
  }

  let classes = props.className;
  if (props.minor) {
    classes += " hero-minor";
  }

  let media = "";
  if (props.video) {
    media = (
      <video id="background-video" autoPlay loop muted poster={props.video}>
        <source src={props.video} type="video/mp4" />
      </video>
    );
  } else if (props.img) {
    media = <img className="" src={props.img} alt="background" />;
  }

  let floater = "";
  if (props.floater) {
    floater = <div className="hero-floater">{props.floater}</div>;
  }

  return (
    <section className={classes + " hero "}>
      <div className="hero-image">
        {coverXML} {media}
      </div>
      {floater}
    </section>
  );
}

//  ? defaults
Hero.defaultProps = {
  className: "",
  coverShadow: 60,
  cover: 0,
};

export default Hero;
