import React from "react";

import Paragraph from "../pageParts/general/Paragraph";
import Button from "../input/Button";
import { Link } from "react-router-dom";


function FourZeroFour() {
  return (
    <div className="flex-col flex-align h-75-vh">
      <Paragraph bold largest>404</Paragraph>
      <Paragraph bold large>Page Not Found.</Paragraph>
      <Link to="/">
        <Button onClick={() => {}} color="red" className="mt-4">
          Go Home
        </Button>
      </Link>
    </div>
  );
}

export default FourZeroFour;
