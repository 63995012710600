export const stringFuncs = {
  maxLetters: (string, max, splitWord = true, ellipsis = true) => {
    let toReturn = string.slice(0, max);
    if (splitWord) {
      // ? split the string into an array of words
      let words = toReturn.split(" ");
      // ? remove the last word
      words.pop();
      // ? join the words back together
      toReturn = words.join(" ");
    }
    if (ellipsis) {
      toReturn += "...";
    }
    return toReturn;
  },
  minLetters: (string, min) => {
    return string.slice(min);
  },
  capitalize: (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  capitalizeFirst: (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  },
};

export const arrayFuncs = {
  makeSafe: (array) => {
    if (!array || array === undefined || array === null || array.length < 1) {
      return [];
    }
    return array;
  },
};

export const objectFuncs = {
  makeSafe: (object) => {
    if (
      !object ||
      object === undefined ||
      object === null ||
      Object.keys(object).length < 1
    ) {
      return {};
    }
    return object;
  },
  // ? a loop through all keys of an object
  forEach: (
    obj,
    func = (key, val) => {
      console.log(key, val);
    }
  ) => {
    Object.keys(obj).forEach((key) => {
      func(key, obj[key]);
    });
  },
};
