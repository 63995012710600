import React from "react";
import { useState } from "react";
import FormMaker from "./FormMaker";
import Paragraph from "../pageParts/general/Paragraph";
import { enquiryCall } from "../../actions/calls";
import { validate } from "../../functions/validate";
import Link from "../input/Link";

const readyToSendText = "Submit Enquiry";

function EnquiryForm(props) {
  const [Data, SetData] = useState({
    type: props.enquiryType,
    message: props.messageText,
  });

  const [ButtonText, SetButtonText] = useState(readyToSendText);
  const [NameValid, SetNameValid] = useState("");
  const [ContactValid, SetContactValid] = useState("");
  const [FailedMsg, SetFailedMsg] = useState("");

  const makeChange = (val, key) => {
    SetData({
      ...Data,
      [key]: val,
    });
  };

  const submitForm = async () => {
    if (ButtonText !== readyToSendText) {
      return;
    }
    const isPhone = validate.isPhone(Data.contact);
    {
      let somethingBad = false;
      if (!Data.contact) {
        SetContactValid("Please enter an email or phone number");
        somethingBad = true;
      } else if (isPhone) {
        const [isValid, badReason] = validate.mobile(Data.contact);
        if (!isValid) {
          SetContactValid(badReason);
          somethingBad = true;
        } else {
          SetContactValid("");
        }
      } else {
        const [isValid, badReason] = validate.email(Data.contact);
        if (!isValid) {
          SetContactValid(badReason);
          somethingBad = true;
        } else {
          SetContactValid("");
        }
      }

      //  ? Validate the name and contact
      const [isValid, badReason] = validate.mixedName(Data.name);
      if (!isValid) {
        SetNameValid(badReason);
        somethingBad = true;
      } else {
        SetNameValid("");
      }

      if (somethingBad) {
        return;
      }
    }

    SetFailedMsg("");
    SetButtonText("Sending...");
    console.log({ ...Data, is_mobile: isPhone });
    // ? send the form
    const success = await enquiryCall({ ...Data, is_mobile: isPhone });

    if (success) {
      SetButtonText("Received");
      setTimeout(() => {
        SetButtonText(readyToSendText);
      }, 5000);
    } else {
      SetButtonText("Failed");
      SetFailedMsg("Something went wrong, please try again");
      setTimeout(() => {
        SetButtonText(readyToSendText);
      }, 5000);
    }
  };

  const inputs = [
    {
      // label: "Your Name",
      type: "text",
      onChange: (e) => {
        makeChange(e.target.value, "name");
      },
      required: true,
      placeHolder: "Your Name",
      value: Data.name,
      className: "",
      light: true,
      flashMsg: [NameValid, 1],
    },
    {
      // label: "Email and Phone",
      type: "text",
      onChange: (e) => {
        makeChange(e.target.value, "contact");
      },
      required: true,
      placeHolder: "Email or Phone",
      value: Data.contact,
      className: "",
      light: true,
      flashMsg: [ContactValid, 1],
    },
    {
      label: "Enquiry Message",
      type: "area",
      onChange: (e) => {
        makeChange(e.target.value, "message");
      },
      placeHolder: props.messageText,
      value: Data.message,
      className: "my-3",
      rows: 5,
      light: true,
    },
  ];

  const buttons = [
    {
      label: ButtonText,
      onClick: submitForm,
      className: "w-100 my-3",
    },
  ];

  return (
    <div className=" w-90">
      <FormMaker
        inputs={inputs}
        buttons={buttons}
        disclaimer={
          <Paragraph className="">
            By submitting this form you agree to the Valhalla Strength
            <Link className="mx-1" link="/article/privacy-policy">
              Privacy Policy
            </Link>
          </Paragraph>
        }
      />
      <Paragraph center>{FailedMsg}</Paragraph>
    </div>
  );
}

export default EnquiryForm;

EnquiryForm.defaultProps = {
  enquiryType: 1,
  messageText: "Hey I’d like more information about the free trial....",
};
