import React from "react";

function LoadingSpinner(props) {
  let innerClass = "loading-spinner-inner";
  if (props.white) {
    innerClass += " loading-spinner-inner-white";
  }

  if (props.center) {
    return (
      <div className={props.className}>
        <div className="page-center">
          <div className="loading-spinner ">
            <div className={"loading-spinner " + innerClass}></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={props.className}>
      <div className="loading-spinner ">
        <div className={"loading-spinner " + innerClass}></div>
      </div>
    </div>
  );
}

export default LoadingSpinner;

// ? defaults
LoadingSpinner.defaultProps = {
  white : false,
  center: false,
  className: "",
};
