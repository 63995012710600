import { navLinks } from "../content/nav";

export const updateTitle = (title) => {
  if (title) {
    document.title = title + " | Valhalla Strength";
    return;
  }
  let path = window.location.pathname;
  navLinks.forEach((item) => {
    if (path === item.link) {
      // ? set the title
      if (path === "/") {
        document.title = "Valhalla Strength - Strength and Condition Gym";
      } else {
        document.title = item.name + " | Valhalla Strength - Strength and Conditioning Gym";
      }
    }
  });
};

export const ls = (key, val = undefined, unset = false) => {
  if (unset && key === "*") {
    localStorage.clear();
    return;
  } else if (unset) {
    localStorage.removeItem(key);
    return;
  }
  if (val === undefined) {
    const data = localStorage.getItem(key);
    return JSON.parse(data);
  } else {
    // ? check if json
    val = JSON.stringify(val);
    localStorage.setItem(key, val);
    return true;
  }
};
