import React from "react";
import { Link } from "react-router-dom";

function Button(props) {
  // ? deconstruct the props object, setting defaults
  const {
    color = 0,
    onClick = () => {
      console.log("Clicked");
    },
    outline = false,
    small = false,
    large = false,
    icon = false,
    slim = false,
    border = false,
    link = false,
    caps = true,
  } = props;

  let colorClass = "";
  if (color) {
    colorClass = `btn-${color}`;
  }

  if (large) {
    colorClass += ` btn-lrg`;
  }

  if (slim) {
    colorClass += ` btn-slim`;
  }

  if (border) {
    colorClass += ` btn-border-${border}`;
  }

  if (outline) {
    colorClass += ` btn-${color}-outline`;
  }

  if (icon) {
    colorClass += ` btn-icon`;
  }

  if (caps) {
    colorClass += ` text-caps`;
  }

  small ? (colorClass += " btn-sml") : (colorClass += "");

  const buttonXML = (
    <button
      onClick={onClick}
      className={props.className + " btn " + colorClass}
    >
      {props.children}
    </button>
  );

  const scrollToTop = () => {
    //  ? scroll to top of page
    window.scrollTo(0, 0);
  };

  if (link) {
    return (
      <Link to={link} onClick={scrollToTop}>
        {buttonXML}
      </Link>
    );
  }

  return buttonXML;
}

export default Button;

// ? default props
Button.defaultProps = {
  color: 0,
  onClick: () => {
    // console.log("Clicked");
  },
  className: "",
  cps: true,
};
