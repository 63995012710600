import React from "react";
import { useState } from "react";
import FormMaker from "./FormMaker";
import { enquiryCall } from "../../actions/calls";
import Paragraph from "../pageParts/general/Paragraph";
import { Link } from "react-router-dom";

function MembershipForm(props) {
  const [ButtonText, SetButtonText] = useState("Get Started");

  const makeChange = (val, key) => {
    props.makeChange(val, key);
  };

  const submitForm = async () => {
    // ? make sure that the name and email arent empty
    if (!props.data.name || !props.data.contact) {
      // ? highlight the field
      // ? add thehighligt to the input
      if (!props.data.name) {
        // ? highlight the name field
      }
      return;
    }

    SetButtonText("Sending...");
    console.log(props.data);
    // ? send the form
    const success = await enquiryCall(props.data);
    if (success) {
      SetButtonText("Received");
      setTimeout(() => {
        SetButtonText("Submit Enquiry");
      }, 5000);
    }
  };

  const inputs = [
    {
      // label: "Your Name",
      type: "text",
      onChange: (e) => {
        makeChange(e.target.value, "name");
      },
      required: true,
      placeHolder: "Your Name",
      value: props.data.name,
      className: "",
      light: true,
    },
    {
      // label: "Email and Phone",
      type: "text",
      onChange: (e) => {
        makeChange(e.target.value, "contact");
      },
      required: true,
      placeHolder: "Email or Phone",
      value: props.data.contact,
      className: "",
      light: true,
    },
  ];

  const buttons = [
    {
      label: ButtonText,
      color: "red",
      onClick: submitForm,
      className: "w-100 my-3",
    },
  ];

  return (
    <div className=" w-100">
      <FormMaker
        inputs={inputs}
        buttons={buttons}
        disclaimer={props.disclaimer}
      />
    </div>
  );
}

export default MembershipForm;

MembershipForm.defaultProps = {
  enquiryType: "general",
  messageText: "Hey I’d like more information about the free trial....",
  disclaimer: (
    <Paragraph small className="my-3">
      By submitting this form you agree to our{" "}
      <Link to="/article/privacy-policy"> Privacy Policy</Link>
    </Paragraph>
  ),
};
