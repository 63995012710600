import React from "react";

function Socials(props) {
  return (
    <div className={"socials-bar flex-row flex-align " + props.className}>
      <figure className="image mx-1">
        <a
          href="https://www.instagram.com/valhalla_strength_brisbane/"
          target="_blank"
        >
          <img
            src="/media/img/base/insta.webp"
            alt="Instagram"
            loading="lazy"
          />
        </a>
      </figure>
      <figure className="image mx-1">
        <a
          href="https://www.facebook.com/valhallastrengthbrisbane/"
          target="_blank"
        >
          <img src="/media/img/base/fb.png" alt="Facebook" loading="lazy" />
        </a>
      </figure>
    </div>
  );
}

export default Socials;
