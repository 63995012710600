import React from "react";

function NavBurger(props) {
  let notificationCount = "";
  if (props.notificationCount) {
    notificationCount = (
      <div className="nav-burger-notification-count">
        {props.notificationCount}
      </div>
    );
  }

  return (
    <button
      className={"nav-burger  align-self-end " + props.openClass}
      onClick={props.onClick}
    >
      <div className="burger-line"></div>
      <div className="burger-line"></div>
      <div className="burger-line"></div>
      {notificationCount}
    </button>
  );
}

export default NavBurger;
