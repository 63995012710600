import React from "react";
import { objectFuncs } from "../../functions/varFuncs";

function Grid(props) {
  let styles = { ...props.styles };
  let classes = "";

  if (props.respCols) {
    objectFuncs.forEach(props.respCols, (key, val) => {
      classes += "grid-resp-" + key + "-" + val + " ";
    });
  } else if (props.columns) {
    styles.gridTemplateColumns = "repeat(" + props.columns + ", 1fr)";
  }
  return (
    <div className={"grid " + props.className + " " + classes} style={styles}>
      {props.children}
    </div>
  );
}

export default Grid;

Grid.defaultProps = {
  columns: 0,
  styles: {},
  className: "",
};
