import React from "react";
import Grid from "../../layout/Grid.js";

function PhotoGrid(props) {
  const images = props.imgs;

  const respColumns = { s: 2, m: 2, l: 4 };

  const imageLoaded = (e) => {
    e.target.parentElement.parentElement.classList.remove(
      "photo-grid-image-loading"
    );
  };

  return (
    <Grid
      className={`${props.className} flex-gap-${props.gap} photo-grid`}
      columns={2}
      respCols={respColumns}
    >
      {images.map((item, index) => {
        let mobHide = "";
        if (item.mobHide) {
          mobHide = " mob-hide ";
        }
        if (props.flash) {
          mobHide += " photo-grid-image-loading ";
        }
        return (
          <div
            key={index}
            className={" photo-grid-image" + mobHide}
          >
            <figure className="image  mb-0">
              <img
                src={item.img}
                onLoad={imageLoaded}
                alt={item.alt}
                loading="lazy"
              />
            </figure>
          </div>
        );
      })}
    </Grid>
  );
}

//?  default props
PhotoGrid.defaultProps = {
  gap: 1,
  className: "",
  flash: true,
};

export default PhotoGrid;
