import React from "react";
import TextArea from "./Area";

function InputField(props) {
  const changed = (e) => {
    return props.onChange(e);
  };

  const scrollTo = (e) => {
    // ? if on mobile
    if (window.innerWidth > 820) {
      return;
    }

    e.target.scrollIntoView({ behavior: "smooth" });
  };

  let step = props.step;
  let lightClass = "";
  if (props.light) {
    lightClass = "form-light";
  }

  let labelXML = "";
  if (props.label) {
    labelXML = (
      <label htmlFor={props.name} className="ml-2 form-label form-label-1">
        {props.label}
      </label>
    );
  }

  if (props.type === "select") {
    let options = props.options;

    // ? turn the options into an array if it is an object
    if (typeof options === "object") {
      options = Object.keys(options).map((key) => {
        return {
          id: key,
          text: options[key],
        };
      });
    }

    return (
      <div className="my-2 w-100">
        {labelXML}
        <select
          name={props.name}
          onChange={changed}
          className={"form-control " + props.className}
          placeholder={props.placeHolder}
          value={props.value}
        >
          {options.map((option) => {
            return (
              <option key={option.id} value={option.id}>
                {option.text}
              </option>
            );
          })}
        </select>
      </div>
    );
  }

  let value = props.value;

  if (props.type === "date") {
    //  ? check if its a date obj
    if (typeof props.value === "object") {
      value = props.value.toISOString();
    }
    if (!props.value) {
      value = "";
    }
    // ? get the date from it
    const date = new Date(value);
    // ? get it in yyyy-mm-dd
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    // ? set it to the value
    value = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;
    step = "";
  }

  if (props.type === "area") {
    return (
      <div className="my-2 w-100">
        {labelXML}
        <TextArea
          onFocus={scrollTo}
          value={value}
          name={props.name}
          onChange={changed}
          type={props.type}
          className={lightClass + " form-control " + props.className}
          placeholder={props.placeHolder}
          step={step}
          min={props.min}
          rows={props.rows}
        />
      </div>
    );
  }

  // if (props.type == "time") {
  //   const date = new Date(props.value);
  //   const hours = date.getHours();
  //   const minutes = date.getMinutes();
  //   // ? set it to the value
  //   // value = `${hours < 10 ? "0" + hours : hours}:${
  //   //   minutes < 10 ? "0" + minutes : minutes
  //   // }`;
  //   console.log(value);
  //   va
  // }

  if (props.min && value < props.min) {
    value = props.min;
  }

  return (
    <div className="my-2 w-100">
      {labelXML}
      <input
        required={props.required}
        onFocus={scrollTo}
        value={value}
        name={props.name}
        onChange={changed}
        type={props.type}
        className={"form-control " + props.className}
        placeholder={props.placeHolder}
        step={step}
        min={props.min}
      />
    </div>
  );
}

export default InputField;

InputField.defaultProps = {
  placeHolder: "Placeholder",
  onChange: () => {
    console.log("Changed");
  },
  label: 0,
  type: "text",
  name: "",
  value: "",
  className: "",
  step: 1,
  min: 0,
  options: {},
};
