import React from "react";
import Link from "../../input/Link";
import Carousel from "../../layout/Carousel ";
import axios from "axios";
import ReviewCard from "../../cards/Review";

// IGQWRPTU5WZAjI4ZADFqZAVoxekFwVWp0X1pNbVRQUzRxQW1RUGhMSmQ2V2FHdEtfSGg3VUR5cDhBVFpFem9iU0k1am9fdHZAqX2RfY0dKS3lQM1dQUVUzTkxKdDhNTk1LejZApYmxQV1BNVEFLdzMwS0VUZAUxaeXRHMm8ZD;
// 5
// 145789603;

// const getReviews = async () => {
// try {
//   axios
//     .get(
//       "https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJR3eYjYRYkWsRFiWI9wpsBqc&fields=reviews&key=AIzaSyAcNkEV0_gV4ztX5f_I2F8wpOVQv75u-N4"
//     )
//     .then((res) => {
//       console.log(res);
//       const persons = res.data;
//       console.log(persons);
//       // this.setState({ persons });
//     });
// } catch {
//   console.log("Failed");
// }
// };
//   try {
//     console.log("Sending");
//     axios({
//       method: "get",
//       url: "https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJR3eYjYRYkWsRFiWI9wpsBqc&key=AIzaSyAdfYDA4Qi3SM2F4JFUMy8aoASXokM1NG8",
//     }).then((res) => {
//       console.log(res);
//     });
//   } catch (err) {
//     console.log("Failed");
//     console.log(err);
//     // return handleCallError(err);
//   }
// };

function ReviewSection(props) {
  // console.log(getReviews());
  // getReviews();

  let reviews = [
    {
      author_name: "Amanda Woods",
      author_url:
        "https://www.google.com/maps/contrib/117836566065504336735/reviews",
      language: "en",
      original_language: "en",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ALV-UjUBHRaVE9rrcdOv-HHaZ1zcOy6Pf5pyt967PEsREHn-3A=s128-c0x00000000-cc-rp-mo",
      rating: 5,
      relative_time_description: "a year ago",
      text: "I have always felt extremely uncomfortable in gyms and initially, I felt very intimidated when I walked into Valhalla. It is unlike a commercial gym as it has a presence, an energy, that at first can be quite confronting, but once you settle in and get to know the coaches and members that presence and energy becomes a contagious positive. The knowledge, experience, and equipment are second to none when it comes to powerlifting and bodybuilding. There is zero judgment around what you look like or how much you lift (well most times ;-P) as we are all working toward the same goal...being strong af! Everyone is willing to help each other out, laughs are a plenty but most of all so is hard work. If that doesn't tell you it's worth taking a look then I don't know what does!",
      time: 1666226278,
      translated: false,
    },
    {
      author_name: "Azh Vrad",
      author_url:
        "https://www.google.com/maps/contrib/113634384792852463154/reviews",
      language: "en",
      original_language: "en",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ALV-UjVphUfyDX4MCnOoN3cnH9j6otkK4Im27U9QO3IN33WEQQ=s128-c0x00000000-cc-rp-mo-ba3",
      rating: 5,
      relative_time_description: "2 months ago",
      text: "Great assortment of equipment, well run and maintained, friendly atmosphere. For the quality and quantity of equipment it's very well priced.\n\nGets busy at certain times but off-peak times are very quiet, I've been able to come in at off-peak times and superset most of my program which I could never dream of doing at an oversubscribed commercial gym like Goodlife.\n\nLocation is a little out of the way but it's off a main road and is quick & easy to drive to.",
      time: 1692577431,
      translated: false,
    },
    {
      author_name: "Jayde Tonks",
      author_url:
        "https://www.google.com/maps/contrib/116993263445878093365/reviews",
      language: "en",
      original_language: "en",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ALV-UjVfK0H9OYN1nFO8n_W0DRIizlHXe8rtV4Z2OTmYrs6kMA=s128-c0x00000000-cc-rp-mo",
      rating: 5,
      relative_time_description: "10 months ago",
      text: "This place has helped build my confidence, motivation and best of all, strength! Strength physically but also mentally. The staff there are all so positive, happy to see you and very encouraging. The equipment is the best of the best and it’s a perfect sized gym for competitions! Have had the best time at Valhalla! Number 1 gym :)",
      time: 1670992935,
      translated: false,
    },
    {
      author_name: "Patrick Brewer",
      author_url:
        "https://www.google.com/maps/contrib/104405861318520511058/reviews",
      language: "en",
      original_language: "en",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a/ACg8ocJ3wuAJm9vYgdBTzNnkY9rXCb7y9BpK9nnm4LGyXS0T=s128-c0x00000000-cc-rp-mo",
      rating: 5,
      relative_time_description: "3 months ago",
      text: "Refreshing to have friendly smiles & hellos at a gym instead of the usual head down and everyone doing their own thing. Feelings of overwhelm & intimidation fade after spending 5 minutes chatting to the big teddy bears & experiencing the welcoming community vibes.",
      time: 1691031863,
      translated: false,
    },
    {
      author_name: "Hanah Mills",
      author_url:
        "https://www.google.com/maps/contrib/114774718047091948510/reviews",
      language: "en",
      original_language: "en",
      profile_photo_url:
        "https://lh3.googleusercontent.com/a-/ALV-UjXl_lp-mxidmDdZPAz6fpsyn88UoZF7R1-IHhrTMo1apg=s128-c0x00000000-cc-rp-mo",
      rating: 5,
      relative_time_description: "11 months ago",
      text: "I have only been at Valhalla for a few months now, but I can easily say it's the best gym I've ever been to (I've been to a lot). The community cheer you on every session to hit your heavy lifts. Though they're all large units, they're the kindest/ least judgemental people you'll ever meet. I immediately felt comfortable and supported at Valhalla Virginia. The also have a team of highly educated coaches that can help improve strength, conditioning and building muscle. 10/10 recommend.",
      time: 1669072161,
      translated: false,
    },
  ];

  if (props.reviews) {
    reviews = props.reviews
  }

  let slides = [];
  reviews.forEach((review) => {
    slides.push({
      xml: (
        <ReviewCard className="" review={review} />
        // <div className="flex-col flex-align" style={{ height: "320px" }}>
        //   <Paragraph className="">{review.author_name}</Paragraph>
        //   <Paragraph className="">{review.text}</Paragraph>
        //   <Paragraph className="">{review.relative_time_description}</Paragraph>
        // </div>
      ),
    });
  });

  return (
    <div className="w-100 flex-col flex-align">
      <Carousel
        slides={slides}
        cardWidth="340px"
        extraSlide={
          <div className="card h-100 card-review flex-col flex-align">
            <Link
              larger
              newTab
              link="https://www.google.com/localservices/prolist?g2lbs=ANTchaMqK_7aJ6WegnK3-aif3av_ZKIKFpGQmgjRVyVZnxgrmalQwTgt8X-KEYmOgI7Qu-ZBbo4Q_pF5x5Xw7KNelJ-5NXKzgHxRmr4HCKSMVQjVnym_NzI%3D&hl=en-AU&gl=au&cs=1&ssta=1&q=valhalla%20strength&oq=valhalla%20strength&slp=MgA6HENoTUloUHVNOHZ5NGdnTVZoZ3A3QngwSW9RNjlSAggCYAB6sgFDaEYyWVd4b1lXeHNZU0J6ZEhKbGJtZDBhRWl2bF9iRXJJLUFnQWhhSXhBQUVBRVlBQmdCSWhGMllXeG9ZV3hzWVNCemRISmxibWQwYUNvR0NBSVFBQkFCa2dFRFozbHRxZ0U2RUFFeUh4QUJJaHZyMW5KMDJaazQxYjFaMk1HNDk1bTJ4cGExU01fODdvWWUxWTB5RlJBQ0loRjJZV3hvWVd4c1lTQnpkSEpsYm1kMGFBkgE4CgwvZy8xaGM0OXcyeWgKDS9nLzExZ2hzOWtuenQKDS9nLzExYzMyNHZjNzYSBBICCAESBAoCCAE%3D&src=2&spp=CgwvZy8xaGM0OXcyeWg6kAFXaU1RQUJBQkdBQVlBU0lSZG1Gc2FHRnNiR0VnYzNSeVpXNW5kR2dxQmdnQ0VBQVFBWklCQTJkNWJab0JBS29CT2hBQk1oOFFBU0liNjlaeWRObVpPTlc5V2RqQnVQZVp0c2FXdFVqUF9PNkdIdFdOTWhVUUFpSVJkbUZzYUdGc2JHRWdjM1J5Wlc1bmRHZz0%3D&serdesk=1&lrlstt=1699603627150&ved=2ahUKEwjLl4Xy_LiCAxVcmlYBHYLgCMsQvS56BAgaEAE&scp=CghnY2lkOmd5bRI4EhIJmwJpq8tZkWsRAJTe81qjAgUiCk51bmRhaCBRTEQqFA3gN6nvFas9OVsdecms7yXL6j1bMAEaEXZhbGhhbGxhIHN0cmVuZ3RoIhF2YWxoYWxsYSBzdHJlbmd0aCoDR3lt#ts=3                                                                                                                                    "
            >
              See More
            </Link>
          </div>
        }
      />
      <Link
        newTab
        link="https://www.google.com/localservices/prolist?g2lbs=ANTchaOeDN_jc-NcdTh_wOHir5iCimx2KtUQr62YYYv5ebcqktJWVa8Znp9aFbXrM7rFiY-evf4eOdxyxGbJAG5gdprPLb-WIRC3xLbOzvPZIwNqHG3aJRUzZev_Vd4nnzIx6pGILb53&hl=en-AU&gl=au&cs=1&ssta=1&q=valhalla%20strength&oq=valhalla%20strength&slp=MgA6HENoTUlydnlHei0tc2dnTVZ5c1NXQ2gzLTNBbERSAggCYAB6sgFDaEYyWVd4b1lXeHNZU0J6ZEhKbGJtZDBhRWl2bF9iRXJJLUFnQWhhSXhBQUVBRVlBQmdCSWhGMllXeG9ZV3hzWVNCemRISmxibWQwYUNvR0NBSVFBQkFCa2dFRFozbHRxZ0U2RUFFeUh4QUJJaHZyMW5KMDJaazQxYjFaMk1HNDk1bTJ4cGExU01fODdvWWUxWTB5RlJBQ0loRjJZV3hvWVd4c1lTQnpkSEpsYm1kMGFBkgE4CgwvZy8xaGM0OXcyeWgKDS9nLzExZ2hzOWtuenQKDS9nLzExYzMyNHZjNzYSBBICCAESBAoCCAE%3D&src=2&spp=CgwvZy8xaGM0OXcyeWg6kAFXaU1RQUJBQkdBQVlBU0lSZG1Gc2FHRnNiR0VnYzNSeVpXNW5kR2dxQmdnQ0VBQVFBWklCQTJkNWJab0JBS29CT2hBQk1oOFFBU0liNjlaeWRObVpPTlc5V2RqQnVQZVp0c2FXdFVqUF9PNkdIdFdOTWhVUUFpSVJkbUZzYUdGc2JHRWdjM1J5Wlc1bmRHZz0%3D&serdesk=1&lrlstt=1699187750286&ved=2ahUKEwiLwf7O76yCAxUwslYBHUIeAY0QvS56BAgdEAE&scp=CghnY2lkOmd5bRI4EhIJmwJpq8tZkWsRAJTe81qjAgUiCk51bmRhaCBRTEQqFA3gN6nvFas9OVsdecms7yXL6j1bMAEaEXZhbGhhbGxhIHN0cmVuZ3RoIhF2YWxoYWxsYSBzdHJlbmd0aCoDR3lt-"
      >
        Leave a Review
      </Link>
    </div>
  );
}

export default ReviewSection;
