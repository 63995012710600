import Axios from "axios";
import { server, devServer } from "../actions/configs";

let devMode = true;

if (devMode) {
}

const handleCallError = (err) => {
  // console.log(err);
  if (err.response) {
    console.log(1);
    console.log(err.response);
    return false;
  } else if (err.request) {
    console.log(2);
    console.log(err.request);
    return false;
  } else if (err.message) {
    console.log(3);
    console.log(err.message);
    return false;
  }
  return false;
};

// const prepForPhp = (input) => {
//   var formData = new FormData();
//   for (var key in input) {
//     formData.append(key, input[key]);
//   }
//   return formData;
// };

export const baseCall = async (url, method = "get", input = null) => {
  try {
    // const data = prepForPhp(input);
    let res = await Axios({
      method: method,
      url: `${devServer.url}${url}`,
      data: input,
      headers: {
        "content-type": "application/json",
        "x-client-key": "39115116622032193701227649",
      },
    });
    return res;
  } catch (err) {
    return handleCallError(err);
  }
};

export const testCall = () => {
  return baseCall("test.php", "get", null, null);
};

export const visits = {
  site: async () => {
    const res = await baseCall("visit.php", "POST", null);
  },
  page: async (page) => {
    const input = {
      page: page,
    };
    const res = await baseCall("visit.php", "POST", input);
  },
};

export const sendData = {
  enquiry: async (input) => {
    const res = await baseCall("enquiry.php", "POST", input);
    return res.data.success;
  },
  visits: async (page = undefined) => {
    let input = {
      page: page,
    };
    if (page === undefined) {
      input = null;
    }
    const res = await baseCall("visit.php", "POST", input);
  },
  mailing: async (data) => {
    console.log(data);
    const res = await baseCall("mailing-signup.php", "POST", data);
    console.log(res);
    return res;
  },
};

export const getData = {
  content: (upd) => {
    //  ? get the time
    let time = new Date().getTime();
    baseCall("content.php", "get", null, null).then(
      (res) => {
        res = res.data;
        // ? log elapsed time
        console.log("Content took: " + (new Date().getTime() - time) + "ms");
        if (res) {
          console.log(res);
          upd(res);
        }
        return res;
      },
      (err) => {
        console.log(err);
        return err;
      }
    );
  },
};

export const localGetContent = (upd) => {
  // ? get the data from the public folder
  try {
    const getData = async () => {
      let res = await Axios({
        method: "GET",
        url: `/content.json`,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
      });
      // console.log(res);
      //  ? handle a bad response
      return res;
    };
    getData().then((res) => {
      res = res.data;
      upd(res);
    });
  } catch (err) {
    return handleCallError(err);
  }
};

export const enquiryCall = async (input) => {
  const res = await baseCall("enquiry.php", "POST", input);
  if (!res) {
    return false;
  }
  return res.data.success;
};

export const reviewCall = (upd) => {
  // ? get the time
  baseCall("reviews.php", "get", null, null).then(
    (res) => {
      res = res.data;
      upd(res);
      return res;
    },
    (err) => {
      console.log(err);
      return err;
    }
  );
};

export const articleCall = (link, upd) => {
  // ? get the time
  baseCall("articles.php", "post", { link: link }, null).then(
    (res) => {
      console.log(res);
      res = res.data.article;
      upd(res);
      return res;
    },
    (err) => {
      console.log(err);
      return err;
    }
  );
};
