import React from "react";
import InputField from "../input/Field";
import CheckBoxInput from "../input/CheckBox";
import Button from "../input/Button";
import Paragraph from "../pageParts/general/Paragraph";

function FormMaker(props) {
  let inputs = props.inputs;
  // ? if props. inputs is not array
  if (!Array.isArray(inputs)) {
    inputs = [];
  }

  let checkBoxes = props.checkBoxes;
  // ? if props. checkBoxes is not array
  if (!Array.isArray(checkBoxes)) {
    checkBoxes = [];
  }

  let buttons = props.buttons;
  // ? if props. buttons is not array
  if (!Array.isArray(buttons)) {
    buttons = [];
  }

  let disclaimer = props.disclaimer;

  return (
    <div className="w-100 flex-col flex-align">
      {inputs.map((input, index) => {
        let flashXML = "";
        if (input.flashMsg && input.flashMsg[0]) {
          flashXML = (
            <Paragraph flash={input.flashMsg[1]}>{input.flashMsg[0]}</Paragraph>
          );
        }
        const makeChange = (e) => {
          input.onChange(e, input.name);
        };
        return (
          <>
            <InputField
              key={index}
              label={input.label}
              type={input.type}
              value={input.value}
              className={input.className}
              placeHolder={input.placeHolder}
              onChange={makeChange}
              options={input.options}
              step={input.step}
              canNegative={input.canNegative}
              min={input.min}
              rows={input.rows}
              required={input.required}
            />
            {flashXML}
          </>
        );
      })}
      {checkBoxes.map((checkBox, index) => {
        return (
          <CheckBoxInput
            key={index}
            label={checkBox.label}
            type={checkBox.type}
            value={checkBox.value}
            onChange={checkBox.onChange}
          />
        );
      })}
      {disclaimer}
      {buttons.map((button, index) => {
        return (
          <Button
            key={index}
            color={button.color}
            outline={button.outline}
            onClick={button.onClick}
            className={button.className}
          >
            {button.label}
          </Button>
        );
      })}
    </div>
  );
}

export default FormMaker;

//  ? default props
FormMaker.defaultProps = {
  inputs: [],
  checkBoxes: [],
  buttons: [],
  disclaimer: "",
};
