import React from "react";

function NavLogo(props) {
  return (
    <div className="nav-logo-image">
      <figure className="image">
        <img src="/media/img/logos/logo-white.png" alt="Logo - Valhalla Strength" />
      </figure>
    </div>
  );
}

export default NavLogo;
