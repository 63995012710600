import React from "react";
import NavLogo from "./NavLogo";
import Socials from "./Socials";

function Footer() {
  return (
    <footer className="">
      <div className=" w-100 row mx-0">
        <div className=" col-4 px-0 mx-0 flex-col flex-align"></div>
        <div className=" col-4 px-0 mx-0 flex-col flex-align">
          <NavLogo />
        </div>
        <div className=" col-4 px-0 mx-0 flex-col flex-align">
          <Socials />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
