import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "../pageParts/nav/Navbar";
import Home from "../pages/Home";
import FourZeroFour from "../pages/FourZeroFour";
import Footer from "../pageParts/nav/Footer";
import { navLinks } from "../../content/nav";
import Loading from "./Loading";
import {
  getData,
  localGetContent,
  reviewCall,
  sendData,
} from "../../actions/calls";
import { ls } from "../../functions/common";

const Memberships = React.lazy(() => import("./Memberships"));
const Competitions = React.lazy(() => import("./Competitions"));
const About = React.lazy(() => import("./About"));
const Contact = React.lazy(() => import("./Contact"));
const Coaching = React.lazy(() => import("./Coaching"));
const Rehabilitation = React.lazy(() => import("./Rehabilitation"));
const Nutrition = React.lazy(() => import("./Nutrition"));
const OnlineCoaching = React.lazy(() => import("./OnlineCoaching"));
const Articles = React.lazy(() => import("./Articles"));

sendData.visits();

function Main(props) {
  // ? read the content from content.json in public
  // console.log(data);
  const [Reviews, SetReviews] = React.useState({});
  const [Content, SetContent] = React.useState(ls("content"));

  useEffect(() => {
    localGetContent(storeContent);
    getData.content(storeContent);
    reviewCall(SetReviews);
  }, []);
  console.log(Content);

  if (!Content || (!Content.cantLoad && !Content.memberships)) {
    SetContent({ cantLoad: 1 });
    return "";
  }

  const lazyLoad = (page, fallBack = <Loading />) => {
    return <React.Suspense fallback={fallBack}>{page}</React.Suspense>;
  };

  const storeContent = (content) => {
    ls("content", content);
    SetContent({ ...content });
  };

  return (
    <div className="main-div">
      <BrowserRouter>
        <Navbar links={navLinks} logout={props.logout} />
        <main className="">
          <Routes className="">
            <Route index element={<Home reviews={Reviews} />} />
            <Route
              path="/memberships"
              element={lazyLoad(
                <Memberships memberships={Content.memberships} />
              )}
            />
            <Route
              path="/competitions"
              element={lazyLoad(
                <Competitions competitions={Content.competitions} />
              )}
            />
            <Route path="/about" element={lazyLoad(<About />)} />
            <Route path="/contact-us" element={lazyLoad(<Contact />)} />
            <Route
              path="/coaching"
              element={lazyLoad(<Coaching coaches={Content.coaches} />)}
            />
            <Route
              path="/online-coaching"
              element={lazyLoad(<OnlineCoaching />)}
            />
            <Route
              path="/rehabilitation"
              element={lazyLoad(<Rehabilitation />)}
            />
            <Route path="/nutrition" element={lazyLoad(<Nutrition />)} />
            <Route
              path="/article/:articleLink"
              element={lazyLoad(<Articles />)}
            />
            <Route path="*" element={<FourZeroFour />} />
          </Routes>
        </main>
        {/* <Footer /> */}
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default Main;
