import React from "react";
import Paragraph from "../pageParts/general/Paragraph";
import { stringFuncs } from "../../functions/varFuncs";
import ReviewStars from "../pageParts/specifics/ReviewStars";
import Image from "../media/Image";
import Link from "../input/Link";

function ReviewCard(props) {
  const review = props.review;
  // console.log(review);
  let text = stringFuncs.maxLetters(review.text, 165);

  return (
    <div className={"card card-review " + props.className}>
      <div className="flex-row">
        <div className="flex-col ">
          <Image
            imgClass="card-review-img image-no-alt"
            img={review.profile_photo_url}
            alt="Profile Picture"
          />
        </div>
        <div className="flex-col mx-3 w-100">
          <Paragraph className="m-0 card-review-author" bold>
            {review.author_name}
          </Paragraph>
          <ReviewStars onlyStars={true} starsCount={review.rating} />
          <Paragraph className="m-0 card-review-time">
            {review.relative_time_description}
          </Paragraph>
        </div>
        <div>
          <Image
            imgClass="card-review-google"
            img="/media/img/base/google.svg"
            alt="Google Logo"
          />
        </div>
      </div>
      <Paragraph>{text}</Paragraph>
      <Link
        className="card-review-link"
        newTab
        link={
          "https://www.google.com/localservices/prolist?g2lbs=ANTchaNA7lYXcejjjh9WZdRJJqi-UrmaBfRsjczFU4QwbW0PrZe6omt23bQ5hijROCLVdDBhv_Hfc4twWudwrCwiX4s_mV5qFdsQqcpxk-xwTdIlMaFE6XuXEMCbQ_yoXWHFZVqWPn3d&hl=en-AU&gl=au&cs=1&ssta=1&q=valhalla%20strength&oq=valhalla%20strength&slp=MgA6HENoTUkydnpia3ZlbWdnTVZnb3RvQ1IyUDFRbE5SAggCYAB6sgFDaEYyWVd4b1lXeHNZU0J6ZEhKbGJtZDBhRWl2bF9iRXJJLUFnQWhhSXhBQUVBRVlBQmdCSWhGMllXeG9ZV3hzWVNCemRISmxibWQwYUNvR0NBSVFBQkFCa2dFRFozbHRxZ0U2RUFFeUh4QUJJaHZyMW5KMDJaazQxYjFaMk1HNDk1bTJ4cGExU01fODdvWWUxWTB5RlJBQ0loRjJZV3hvWVd4c1lTQnpkSEpsYm1kMGFBkgE4CgwvZy8xaGM0OXcyeWgKDS9nLzExZ2hzOWtuenQKDS9nLzExYzMyNHZjNzYSBBICCAESBAoCCAE%3D&src=2&spp=CgwvZy8xaGM0OXcyeWg6kAFXaU1RQUJBQkdBQVlBU0lSZG1Gc2FHRnNiR0VnYzNSeVpXNW5kR2dxQmdnQ0VBQVFBWklCQTJkNWJab0JBS29CT2hBQk1oOFFBU0liNjlaeWRObVpPTlc5V2RqQnVQZVp0c2FXdFVqUF9PNkdIdFdOTWhVUUFpSVJkbUZzYUdGc2JHRWdjM1J5Wlc1bmRHZz0%3D&serdesk=1&lrlstt=1698983613168&ved=2ahUKEwixtMiS96aCAxUFSGwGHU10CtgQvS56BAgXEAE&scp=CghnY2lkOmd5bRI4EhIJmwJpq8tZkWsRAJTe81qjAgUiCk51bmRhaCBRTEQqFA3gN6nvFas9OVsdecms7yXL6j1bMAEaEXZhbGhhbGxhIHN0cmVuZ3RoIhF2YWxoYWxsYSBzdHJlbmd0aCoDR3lt"
        }
      >
        Read More <i className="fa fa-chevron-right"></i>
      </Link>
    </div>
  );
}

export default ReviewCard;
