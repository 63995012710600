import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function ModalBody(props) {
  let extraClass = "";
  if (!props.isOpen) extraClass = "d-none ";

  let titleXML = <div></div>;
  if (props.title) titleXML = <h1 className="modal-title">{props.title}</h1>;

  return (
    <div
      onClick={props.close}
      className={"modal-background " + extraClass + props.className}
    >
      <div
        className={"modal-body " + props.bodyClass}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className="flex-row flex-align flex-space w-100"
          onClick={props.close}
        >
          {titleXML}
          <div className="modal-close" onClick={props.close}>
            {/* <FontAwesomeIcon icon="times" />X */}
            <i className="fas fa-times"></i>
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default ModalBody;
