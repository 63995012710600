import React, { useEffect, useState } from "react";

import Section from "../pageParts/general/Section";
import Paragraph from "../pageParts/general/Paragraph";
import Button from "../input/Button";
import Hero from "../pageParts/Hero";
import EnquiryForm from "../forms/Enquiry";
import ReviewStars from "../pageParts/specifics/ReviewStars";
import { updateTitle } from "../../functions/common";
import MapSection from "../pageParts/sections/MapSection";
import InstaSection from "../pageParts/sections/InstaSection";
import ReviewSection from "../pageParts/sections/ReviewSection";
import Parallax from "../media/Parallax";
import PhotoSection from "../pageParts/sections/PhotoSection";
import ModalBody from "../pageParts/modal/Body";
import MembershipForm from "../forms/Membership";
import { visits } from "../../actions/calls";

function Home(props) {
  const [ModalOpen, SetModalOpen] = useState(0);
  const [Data, SetData] = useState({
    type: "Trial",
  });

  useEffect(() => {
    visits.page(2);
  }, []);

  const makeChange = (val, key) => {
    SetData({
      ...Data,
      [key]: val,
    });
  };

  let reviews = [];
  updateTitle();
  let AverageStars = 4.9;
  let fiveStarCount = 84;
  if (props.reviews && props.reviews.rating && props.reviews.total_reviews) {
    AverageStars = props.reviews.rating;
    fiveStarCount = props.reviews.total_reviews;
    reviews = props.reviews.reviews;
  }

  return (
    <>
      <ModalBody isOpen={ModalOpen} close={() => SetModalOpen(false)}>
        <Paragraph header largest className="mb-3">
          Free Trial Pass
        </Paragraph>
        <MembershipForm
          close={() => SetModalOpen(false)}
          makeChange={makeChange}
          data={Data}
        />
        <Paragraph med small mo className="mt-3">
          After submitting this form, a member of our friendly team will be in
          touch with you to arrange your first session and get you started!
        </Paragraph>
      </ModalBody>

      <Parallax imgs={[{ src: "/media/img/kit/8.webp" }]} />
      <Hero
        video={"/media/video/hero.mov"}
        coverShadow={100}
        floater={
          <div className="float-area mx-auto flex-col flex-align">
            <Paragraph component="h1" bold large className="mx-2 mb-3" center>
              A choice like no other for Powerlifting, Bodybuilding and Weight
              Training in Brisbane
            </Paragraph>
            <Button large link="/memberships">
              View Memberships
            </Button>
          </div>
        }
      />
      <Section>
        <Paragraph header component="h1" color="red">
          Open 24/7
        </Paragraph>
        <PhotoSection />
      </Section>
      <Section containerWidth={3} className="">
        <ReviewStars starsCount={AverageStars} />
        <Paragraph className="" mo med>
          {fiveStarCount} total reviews
        </Paragraph>
        <ReviewSection reviews={reviews} />
      </Section>
      <Section
        containerWidth={2}
        className="text-white"
        // bgImg={curl}
        seeThrough={true}
        bgShadow={82}
        py={5}
      >
        <Paragraph header className="" component="h1" center>
          TRY US FOR FREE!
        </Paragraph>
        <Paragraph className="mb-4 px-3" center>
          We’re sure you’re going to love it here at Valhalla Strength, so why
          not try use out for free first? We offer 5 Day Free Trials to local
          residents which can be redeemed during any of our staffed hours!
        </Paragraph>
        <Button
          onClick={() => {
            SetModalOpen(true);
          }}
          large
          className="w-70"
        >
          Free Trail Pass
        </Button>
      </Section>
      <Section containerWidth={6} className="">
        <InstaSection />
      </Section>
      {/* <Section
        py={5}
        containerWidth={1}
        className="text-white"
        // bgImg={curl}
        seeThrough={true}
        bgShadow={82}
      >
        <Paragraph lead bold className="">
          Valhalla Strength has proudly been one of Brisbane's leading Strength
          Training Gyms for <span className="text-red">over 10 years!</span>
        </Paragraph>
        <Paragraph small className="mb-2">
          Equipment and facility aside, what truly sets this place apart and
          makes it special is the community within it. We have a culture and
          presence here unlike anywhere else. If you take your training
          seriously or are after somewhere to start a fresh journey with
          support, friendliness and absolutely not judgment this is the place
          for you!
        </Paragraph>
        <Paragraph small className="mb-4">
          Privately owned and operated, we are a 24/7 membership gym built from
          passion and for the lifter. We cater for absolutely anyone, from first
          time beginners to the elite!
        </Paragraph>
      </Section> */}
      {/* <Section conta2inerWidth={1} className=""> */}
      {/* </Section> */}
      <Section
        className="text-white p-2"
        // bgImg={curl}
        seeThrough={true}
        bgShadow={82}
        respSplit
      >
        <MapSection />
        <div className="w-100 max-w-1 flex-col flex-align">
          <Paragraph header className="mb-2 mx-z-3" center larger>
            Get In touch
          </Paragraph>
          <Paragraph bold className="mb-2 mx-z-3" center component="h5">
            Join Now and we will waive all joining and start up fees! (valued at
            $150)
          </Paragraph>
          <EnquiryForm />
        </div>
      </Section>
    </>
  );
}

export default Home;
