import React from "react";
// import Form from "react-bootstrap/Form";

function CheckBoxInput(props) {
  const switchClicked = (e) => {
    props.onChange(props.value);
  };

  return (
    <div
      className="mx-2 flex-row flex-align flex-space"
      onClick={switchClicked}
    >
      <label htmlFor={props.name} className="ml-2 form-label form-label-1">
        {props.label}
      </label>
      {/* <Form.Check 
        type="switch"
        id={props.name}
        checked={props.value}
      /> */}
    </div>
  );
}

export default CheckBoxInput;

CheckBoxInput.defaultProps = {
  name: "",
  key: "",
  label: "",
  checked: false,
  onChange: () => {},
};
