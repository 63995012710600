import React, { useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";

const GoogleMaps = (props) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAdfYDA4Qi3SM2F4JFUMy8aoASXokM1NG8",
  });

  const initialMarkers = props.markers;

  const [activeInfoWindow, setActiveInfoWindow] = useState(null);
  const [Markers, setMarkers] = useState(initialMarkers);

  const containerStyle = props.containerStyle;

  const mapClicked = (event) => {
    // console.log(event.latLng.lat(), event.latLng.lng());
  };

  const markerClicked = (marker, index) => {
    setActiveInfoWindow(index);
    // console.log(marker, index);
  };

  const markerDragEnd = (event, index) => {
    // console.log(event.latLng.lat(), event.latLng.lng());
  };

  return (
    isLoaded && (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={props.center}
        zoom={props.zoom}
        onClick={mapClicked}
      >
        {Markers.map((marker, index) => (
          <Marker
            key={index}
            position={marker.position}
            label={marker.label}
            draggable={marker.draggable}
            onDragEnd={(event) => markerDragEnd(event, index)}
            onClick={(event) => markerClicked(marker, index)}
          >
            {activeInfoWindow === index && (
              <InfoWindow
                position={marker.position}
                onCloseClick={() => setActiveInfoWindow(null)}
              >
                <b>
                  {marker.position.lat}, {marker.position.lng}
                </b>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
    )

  );
};

export default GoogleMaps;

// ? defualts
GoogleMaps.defaultProps = {
  zoom: 12,
  center: {
    lat: -27.3834198,
    lng: 153.0707405,
  },
  markers: [
    // {
    //   position: {
    //     lat: -27.3834198,
    //     lng: 153.0707405,
    //   },
    //   label: { color: "black", text: "P1" },
    //   draggable: false,
    // },
  ],
  containerStyle: {
    width: "100%",
    height: "400px",
  },
};
