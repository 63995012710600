import React from "react";

function Paragraph(props) {
  let classes = props.className;
  if (props.subtext) {
    classes += " paragraph-subtext";
  }
  if (props.bold) {
    classes += " paragraph-bold";
  } else if (props.med) {
    classes += " paragraph-med";
  }

  if (props.center) {
    classes += " paragraph-center";
  }

  if (props.large) {
    if (props.large === 1) {
      classes += " paragraph-large-1";
    } else if (props.large === 2) {
      classes += " paragraph-large-2";
    } else {
      classes += " paragraph-large";
    }
  } else if (props.larger) {
    classes += " paragraph-larger";
  } else if (props.largest) {
    classes += " paragraph-largest";
  } else if (props.small) {
    classes += " paragraph-small";
  } else if (props.smaller) {
    classes += " paragraph-smaller";
  } else if (props.medium) {
    classes += " paragraph-medium";
  }

  if (props.mo) {
    classes += " m-0";
  }

  let headingXML = "";
  if (props.heading) {
    headingXML = <h1>{props.heading}</h1>;
  }

  if (props.header) {
    classes += " paragraph-header";
  }

  if (props.accent) {
    classes += " text-accent";
  }

  if (props.white) {
    classes += " text-white";
  }

  if (props.flash) {
    classes += " flash-msg flash-msg-" + props.flash + " ";
  }

  let content = "";
  switch (props.component) {
    case "p":
      content = (
        <p style={props.styles} className={"paragraph " + " " + classes}>
          {props.children}
        </p>
      );
      break;
    case "span":
      content = (
        <span style={props.styles} className={"paragraph " + " " + classes}>
          {props.children}
        </span>
      );
      break;
    case "div":
      content = (
        <div style={props.styles} className={"paragraph " + " " + classes}>
          {props.children}
        </div>
      );
      break;
    case "h1":
      content = (
        <h1 style={props.styles} className={"paragraph " + " " + classes}>
          {props.children}
        </h1>
      );
      break;
    case "h2":
      content = (
        <h2 style={props.styles} className={"paragraph " + " " + classes}>
          {props.children}
        </h2>
      );
      break;
    case "h3":
      content = (
        <h3 style={props.styles} className={"paragraph " + " " + classes}>
          {props.children}
        </h3>
      );
      break;
    case "h4":
      content = (
        <h4 style={props.styles} className={"paragraph " + " " + classes}>
          {props.children}
        </h4>
      );
      break;
    case "h5":
      content = (
        <h5 style={props.styles} className={"paragraph " + " " + classes}>
          {props.children}
        </h5>
      );
      break;
    case "h6":
      content = (
        <h6 style={props.styles} className={"paragraph " + " " + classes}>
          {props.children}
        </h6>
      );
      break;
    default:
      content = (
        <p style={props.styles} className={"paragraph " + " " + classes}>
          {props.children}
        </p>
      );
      break;
  }

  return (
    <>
      {headingXML}
      {content}
    </>
  );
}

export default Paragraph;

Paragraph.defaultProps = {
  bold: false,
  larger: false,
  heading: "",
  className: "",
  styles: {},
  component: "p",
};
