import React from "react";
import Paragraph from "../general/Paragraph";
import InstaTag from "../specifics/InstaTag";
import PhotoGrid from "../general/PhotoGrid";
import Button from "../../input/Button";

function InstaSection(props) {
  return (
    <div className="w-100 flex-col flex-align">
      <Paragraph header className="">
        INSTAGRAM FEED
      </Paragraph>
      <InstaTag
        username="@valhalla_strength_brisbane"
        imageUrl={"/media/img/logos/insta-logo.jpg"}
      />
      <PhotoGrid
        className="mx-3"
        imgs={[
          { img: "/media/img/kit-low-res/1.webp", alt: "" },
          { img: "/media/img/kit-low-res/2.webp", alt: "" },
          { img: "/media/img/kit-low-res/3.webp", alt: "" },
          { img: "/media/img/kit-low-res/4.webp", alt: "" },
          { img: "/media/img/kit-low-res/1.webp", alt: "", mobHide: true },
          { img: "/media/img/kit-low-res/2.webp", alt: "", mobHide: true },
          { img: "/media/img/kit-low-res/3.webp", alt: "", mobHide: true },
          { img: "/media/img/kit-low-res/4.webp", alt: "", mobHide: true },
        ]}
      />
      <a
        href="https://www.instagram.com/valhalla_strength_brisbane/"
        target="_blank"
      >
        <Button className="mt-1 mb-3" slim border="white">
          Follow us on Instagram
        </Button>
      </a>
    </div>
  );
}

export default InstaSection;
