import React, { useState } from "react";

function Carousel(props) {
  const animationTime = props.animationTime;
  let slideAnimation = "left " + animationTime + "ms ease-in-out";
  const [Index, SetIndex] = useState(0);
  const [TouchPoint, SetTouchPoint] = useState(false);
  const [TouchSlide, SetTouchSlide] = useState(0);
  const [CarouselAnimation, SetCarouselAnimation] = useState(slideAnimation);

  const slides = props.slides;

  const changeSlide = (e, selectedIndex = undefined, toIndex = 0) => {
    if (selectedIndex === undefined) {
      selectedIndex = 1;
    }
    if (!toIndex) {
      SetIndex(Index + selectedIndex);
      if (Index + selectedIndex >= slides.length) {
        // console.log("Loop Forward");
        SetIndex(0);
      } else if (Index + selectedIndex < 0) {
        // console.log(Index - 1);
        // console.log("Loop Back");
        SetIndex(slides.length - 1);
      }
    } else {
      SetIndex(selectedIndex);
    }
  };

  const goBackSlide = () => {
    // console.log("Back");
    changeSlide(0, -1);
  };

  // ? plit it into an array of numbers and units
  let cardWidthArray = props.cardWidth.split(/(\d+)/);
  cardWidthArray = cardWidthArray.filter((item) => item !== "");
  // console.log(cardWidthArray);

  // ? make the carousel move when dragged

  const touchStart = (e) => {
    if (!e.changedTouches || !e.changedTouches[0]) {
      return;
    }
    let touchX = e.changedTouches[0].clientX;
    SetTouchPoint(touchX);
    SetCarouselAnimation(slideAnimation);
  };

  const touchMove = (e) => {
    if (!e.changedTouches || !e.changedTouches[0]) {
      return;
    }
    let touchX = e.changedTouches[0].clientX;
    let deltaX = TouchPoint - touchX;
    let carouselWidth = window.innerWidth;
    let slideWidth = (deltaX / cardWidthArray[0]) * 100;
    if (Math.abs(slideWidth + TouchSlide) > 10) {
      SetTouchSlide(slideWidth);
    }
  };

  const touchEnd = (e) => {
    if (!e.changedTouches || !e.changedTouches[0]) {
      return;
    }
    let touchX = e.changedTouches[0].clientX;
    let deltaX = TouchPoint - touchX;
    // ? get the width of the screen
    let carouselWidth = window.innerWidth;
    // ? get the width of the slide as percentage of the screen
    let slideWidth = (deltaX / cardWidthArray[0]) * 100;
    // ? if the slide is more than 50% of the screen, go to the next slide
    if (slideWidth > 50) {
      slideWidth += 50;
    } else if (slideWidth < -50) {
      slideWidth -= 50;
    }
    slideWidth = Math.round(slideWidth / 100);
    changeSlide(0, slideWidth);
    // if (slideWidth > 150) {
    //   changeSlide(0, 2);
    // } else {
    //   changeSlide(0, 1);
    // }
    //   if (slideWidth < -150) {
    //     changeSlide(0, -2);
    //   } else {
    //     changeSlide(0, -1);
    //   }
    // }
    SetTouchSlide(0);
    SetCarouselAnimation(
      slideAnimation + ", transform " + animationTime + "ms ease-in-out"
    );
  };

  let leftPercent = (-1 * TouchSlide * cardWidthArray[0]) / 100;
  // console.log(leftPercent + cardWidthArray[1]);

  let dotsArray = [];
  for (let i = 0; i < slides.length; i++) {
    const changeToSlide = () => {
      changeSlide(0, i, 1);
    };
    let dotXML = (
      <div
        data-index={i}
        key={i}
        className=" carousel-dot "
        onClick={changeToSlide}
      >
        <div className=" carousel-dot-icon"></div>
      </div>
    );
    if (Index == i) {
      dotXML = (
        <div
          data-index={i}
          key={i}
          className=" carousel-dot carousel-dot-active"
          onClick={changeToSlide}
        >
          <div className=" carousel-dot-icon"></div>
        </div>
      );
    }
    dotsArray.push(dotXML);
  }

  let extraSlide = "";
  if (props.extraSlide) {
    extraSlide = <div className="carousel-slide">{props.extraSlide}</div>;
  }

  return (
    <div
      onMouseDown={touchStart}
      onMouseMove={touchMove}
      onMouseUp={touchEnd}
      onTouchStart={touchStart}
      onTouchMove={touchMove}
      onTouchEnd={touchEnd}
      className="carousel-container"
    >
      <div
        className="carousel-arrows"
        onClick={goBackSlide}
        style={{ left: "0" }}
      >
        <i className="fa fa-chevron-left"></i>
      </div>
      <div
        className="carousel-arrows"
        onClick={changeSlide}
        style={{ left: "calc(100% - 30px)" }}
      >
        <i className="fa fa-chevron-right"></i>
      </div>
      <div className="carousel-hider">
        <div
          // onClick={changeSlide}
          className="carousel"
          style={{
            transform: "translateX(" + leftPercent + cardWidthArray[1] + ")",
            left: cardWidthArray[0] * Index * -1 + cardWidthArray[1],
            transition: CarouselAnimation,
          }}
        >
          {slides.map((slide, index) => {
            return (
              <div key={index} className="carousel-slide">
                {slide.xml}
              </div>
            );
          })}
          {extraSlide}
        </div>
      </div>
      <div className="flex-row flex-align">
        {dotsArray.map((dot, index) => {
          return dot;
        })}
      </div>
    </div>
  );
}

export default Carousel;

// ? do default props
Carousel.defaultProps = {
  cardWidth: "100%",
  slideAfterTime: 1000,
  animationTime: 500,
  slides: [
    {
      xml: (
        <div className="flex-col flex-align">
          <h1>Data 1</h1>
          <p>Body 1</p>
        </div>
      ),
    },
    {
      xml: (
        <div className="flex-col flex-align">
          <h1>Data 2</h1>
          <p>Body 2</p>
        </div>
      ),
    },
    {
      xml: (
        <div className="flex-col flex-align">
          <h1>Data 3</h1>
          <p>Body 3</p>
        </div>
      ),
    },
    {
      xml: (
        <div className="flex-col flex-align">
          <h1>Data 4</h1>
          <p>Body 4</p>
        </div>
      ),
    },
  ],
};
