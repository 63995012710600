export const validate = {
  email: (email) => {
    if (!email) {
      return [false, "Please enter an email address"];
    }
    email = email.replace(/\s/g, "");
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    let reason = "The provided was not a valid email address";
    let passed = regex.test(email);
    if (passed) {
      return [true, email];
    } else {
      return [false, reason];
    }
  },
  mobile: (mobile) => {
    if (!mobile) {
      return [false, "Please enter a mobile number"];
    }
    mobile = mobile.replace(/\s/g, "");
    // ? if it contains only numbers, spaces and plus signs and is at least 9 characters long
    const regex = /^[0-9 +]{9,15}$/;
    let reason = "The provided was not a valid mobile number";
    let passed = regex.test(mobile);
    if (passed) {
      return [true, mobile];
    } else {
      return [false, reason];
    }
  },
  mixedName: (name) => {
    if (!name) {
      return [false, "Please enter a name, we need to know what to call you"];
    }
    name = name.trim();
    const regex = /^[a-zA-Z0-9 ]*$/;
    let reason = "The provided name contained invalid characters";
    let passed = regex.test(name);
    if (passed) {
      return [true, name];
    } else {
      return [false, reason];
    }
  },
  isPhone: (contact) => {
    if (!contact) {
      return false;
    }
    // ? if it contains only numbers, spaces and plus signs
    contact = contact.trim();
    const regex = /^[0-9 +]*$/;
    let isPhone = regex.test(contact);
    if (isPhone) {
      return true;
    }
    return false;
  },
};
