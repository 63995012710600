import React from "react";

function Image(props) {
  return (
    <div className="image-box w-100">
      <figure className={"image mb-0 " + props.className}>
        <img
          className={props.imgClass}
          src={props.img}
          alt={props.alt}
          loading={props.loading}
        />
      </figure>
      <div className="image-box-overlay">{props.overlay}</div>
    </div>
  );
}

// ? default props
Image.defaultProps = {
  loading: "lazy",
  className: "",
};

export default Image;
