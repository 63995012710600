import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Socials from "./Socials";
import { useState } from "react";

function NavList(props) {
  const [SublistOpen, SetSublistOpen] = useState(0);
  const [CurrentPath, SetCurrentPath] = useState(0);
  const location = useLocation();

  useEffect(() => {}, [location]);

  const closeOnMouseOut = (e) => {
    SetSublistOpen(0);
  };

  const sublistClicked = (id) => {
    if (SublistOpen === id) {
      SetSublistOpen(0);
    } else {
      SetSublistOpen(id);
    }
  };

  let openClass = "";
  if (props.isOpen) {
    openClass = "nav-link-list-opened";
  }

  let path = window.location.pathname;

  const closeOnClick = () => {
    console.log("Close on Click");
    props.SetOpened(false);
    SetCurrentPath(CurrentPath + 1);
    SetSublistOpen(0);
  };

  return (
    <div className="nav-background">
      <div
        className={"nav-link-list " + openClass}
        // style={{ backgroundImage: curl }}
      >
        <Socials className="mx-3 mb-4" />
        <ul>
          {props.list.map((item, index) => {
            let divider = "";
            if (item.divider) {
              divider = <div className="nav-link-list-divider"></div>;
            }

            if (item.subList) {
              const isOpen = SublistOpen === item.listId;
              const setOpen = () => {
                sublistClicked(item.listId);
              };
              let littleArrow = <i className="fas fa-chevron-down"></i>;
              let divClass = "sub-list ";
              let linkClass = "";
              if (isOpen) {
                // littleArrow = <i className="fas fa-chevron-up"></i>;
                divClass = "sub-list-open";
                linkClass = "sub-list-opener";
              }
              item.subList.forEach((subLink) => {
                if (path === subLink.link) {
                  linkClass += " active ";
                }
              });
              let thisXML = (
                <li key={index} onClick={setOpen} className={linkClass}>
                  {item.name} {littleArrow}
                </li>
              );
              return (
                <div
                  className={" " + divClass}
                  key={index}
                  onMouseLeave={closeOnMouseOut}
                >
                  {divider}
                  {thisXML}
                  <ul className="nav-link-list-sub">
                    {item.subList.map((subItem, subIndex) => {
                      let addClass = "";
                      if (path === subItem.link) {
                        addClass = "active";
                      }
                      let thisXML = (
                        <li
                          onClick={closeOnClick}
                          key={subIndex}
                          className={addClass}
                        >
                          {subItem.name}
                        </li>
                      );
                      return (
                        <div key={subIndex}>
                          {divider}
                          <Link to={subItem.link}>{thisXML}</Link>
                        </div>
                      );
                    })}
                  </ul>
                </div>
              );
            }

            const itemClicked = () => {
              if (item.onClick) {
                item.onClick();
              }
              closeOnClick();
            };
            let addClass = "";
            if (path === item.link) {
              addClass = "active";
            }
            let thisXML = (
              <li key={index} onClick={itemClicked} className={addClass}>
                {item.name}
              </li>
            );
            return (
              <div key={index}>
                {divider}
                <Link to={item.link}>{thisXML}</Link>
              </div>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default NavList;
