import React from "react";

function TextArea(props) {
  return (
    <div>
      <textarea
        onChange={props.onChange}
        className={"form-control w-100"}
        rows={props.rows}
        value={props.value}
      />
    </div>
  );
}

export default TextArea;
