import React from "react";

import Paragraph from "../pageParts/general/Paragraph";
import LoadingSpinner from "../fancyThings/LoadingSpinner";

function Loading() {
  return (
    <div className="flex-col flex-align h-75-vh">
      <Paragraph bold largest>
        Loading...
      </Paragraph>
      <Paragraph >Wont be a moment.</Paragraph>
      <LoadingSpinner />
    </div>
  );
}

export default Loading;
