import React from "react";
import stars from "../../../img/stars.png";
import Paragraph from "../general/Paragraph";

const ReviewStars = ({ starsCount, onlyStars }) => {
  starsCount = Math.round(starsCount * 10) / 10;
  let starPercent = starsCount * 20;
  // ? accounts for the offset oof the star image not having the corect width
  starPercent += (starPercent - 50) * -0.0025;

  if (onlyStars) {
    return (
      <div className="review-stars-holder">
        <div
          className="review-stars-cover"
          style={{ width: starPercent + "%" }}
        >
          <img
            className="review-stars-img"
            src={stars}
            alt="Instagram Profile Picture"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="px-3 w-100 review-stars flex-col  flex-align">
      <Paragraph bold className="m-0">
        <span className="review-stars-number">{starsCount}</span> Average Stars
      </Paragraph>
      <div className="review-stars-holder">
        <div
          className="review-stars-cover"
          style={{ width: starPercent + "%" }}
        >
          <img
            className="review-stars-img"
            src={stars}
            alt="Instagram Profile Picture"
          />
        </div>
      </div>
    </div>
  );
};

export default ReviewStars;
