import React from "react";
import PhotoGrid from "../general/PhotoGrid";

function PhotoSection() {
  return (
    <PhotoGrid
      className="mx-3 my-4 "
      imgs={[
        {
          img: "/media/img/kit-low-res/1.webp",
          alt: "Squat Racks",
          date: "12/12/12",
        },
        {
          img: "/media/img/kit-low-res/2.webp",
          alt: "Gym Main Floor",
          date: "12/12/12",
        },
        {
          img: "/media/img/kit-low-res/3.webp",
          alt: "Gym Main Floor",
          date: "12/12/12",
        },
        {
          img: "/media/img/kit-low-res/4.webp",
          alt: "Gym Main Floor",
          date: "12/12/12",
        },
        {
          img: "/media/img/kit-low-res/5.webp",
          alt: "Gym Main Floor",
          date: "12/12/12",
        },
        {
          img: "/media/img/kit-low-res/6.webp",
          alt: "Gym Main Floor",
          date: "12/12/12",
        },
        {
          img: "/media/img/kit-low-res/7.webp",
          alt: "Gym Main Floor",
          date: "12/12/12",
        },
        {
          img: "/media/img/kit-low-res/8.webp",
          alt: "Gym Main Floor",
          date: "12/12/12",
        },
      ]}
    />
  );
}

export default PhotoSection;
