import React from "react";
import Paragraph from "../pageParts/general/Paragraph";

function Parallax(props) {
  let classes = "parallax ";

  let imgs = [];
  if (props.imgs) {
    props.imgs.forEach((img, i) => {
      imgs.push(
        <div
          key={i}
          className="parallax"
          style={{ backgroundImage: img.src }}
        ></div>
      );
    });
  } else {
    imgs = [<div key='1' className="parallax"></div>];
  }

  return (
    <div className="parallax-holder">
      {imgs.map((img) => {
        return img;
      })}
    </div>
  );
}

export default Parallax;

//  ? defaults
Parallax.defaultProps = {
  className: "",
  imgs: false,
};
